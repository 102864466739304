import * as React from "react";
import PageHelmet from "../components/common/helmet";
import { Col, Container, Row } from "react-bootstrap";

const NotFoundPage: React.FC<any> = () => {
  return (
    <>
      <PageHelmet title="404 Not Found" />
      <Container>
        <Row>
          <Col>
            <div
              style={{
                padding: "40px 15px",
                textAlign: "center",
              }}
            >
              <h1>Oops!</h1>
              <h2>404 Not Found</h2>
              <div className="error-details">
                Sorry, an error has occured, Requested page not found!
              </div>
              <div
                className="error-actions"
                style={{
                  marginTop: 15,
                  marginBottom: 15,
                }}
              >
                <a
                  href={process.env.GATSBY_HOST}
                  className="btn btn-primary btn-lg"
                >
                  Take Me Home
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default NotFoundPage;
